import { readState } from "@/__main__/app-state.mjs";
import getData from "@/__main__/get-data.mjs";
import * as API from "@/game-deadlock/api.mjs";
import HeroesStats from "@/game-deadlock/models/heroesStats.mjs";
import HeroItemStats from "@/game-deadlock/models/heroItemStats.mjs";
import { getHeroIdFromName } from "@/game-deadlock/utils/getHeroIdFromName.mjs";

export default async function fetchData(
  params: FixedLengthArray<string, 1>,
  _searchParam: URLSearchParams,
): Promise<void> {
  const heroKeyOrId = params[0];
  const heroId = getHeroIdFromName(heroKeyOrId, readState.deadlock.heroes);

  const gameMode = "Normal";
  const matchMode = "Unranked";

  // All heroes stats
  const heroesStats = getData(
    API.getHeroesStats({ gameMode, matchMode }),
    HeroesStats,
    ["deadlock", "heroesStats"],
    {
      shouldFetchIfPathExists: true,
    },
  );

  // Single hero item stats
  const heroItemStats = getData(
    API.getHeroItemStats({ gameMode, matchMode, heroId: `${heroId}` }),
    HeroItemStats,
    ["deadlock", "heroItemsStats", heroId],
    {
      shouldFetchIfPathExists: true,
    },
  );

  await Promise.all([heroesStats, heroItemStats]);
}
